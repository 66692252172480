@import "mixins";
@import "variables";


/*COMMON START*/
/*common styles for different sections and pages, common classes, buttons*/
/*--------------------------------------------------------------------------*/


/*TEMPLATE CLASSES START*/
/*--------------------------------------------------------------------------*/

.vertical-middle-parent {
	display: table;
	width: 100%;
	height: 100%; }

.vertical-middle {
	display: table-cell;
	vertical-align: middle; }

.vertical-bottom {
	display: table-cell;
	vertical-align: bottom; }

.img-full-width {
	display: block;
	width: 100%;
	height: auto; }

.main {
	display: block; }

$offset: 15;
$containerWidth: 1170;

.container,
.owl-dots {
	position: relative;
	max-width: $containerWidth + 2*$offset + px; }

.row {
	margin-right: -$offset + px;
	margin-left: -$offset + px; }

.container,
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9,
.col-md-1-5 {
	padding-right: $offset + px;
	padding-left: $offset + px; }

.pos-rel {
	position: relative; }

.z1 {
	z-index: 1; }

.shadow {
	box-shadow: 0 0 5px 0px rgba(#000, 0.11); }

.bottom-offset {
 }	// margin-bottom: 30px

.bottom-offset-small {
 }	// margin-bottom: 15px

.mb0 {
	margin-bottom: 0; }

.media-body.dots {
	word-break: break-all; }

/*selected text background*/
@include selection(rgba($violet, 0.3));

.middle {
	@include middle; }













/*--------------------------------------------------------------------------*/
/*TEMPLATE CLASSES END*/



.dropdown-menu {
	border-radius: 0;
	margin-top: 0; }

.text-muted {
	color: #8a8585; }

.text-muted-light {
	color: $mutedLight; }

.side-padding {
	padding-right: 30px;
	padding-left: 30px; }




/*--------------------------------------------------------------------------*/
/*COMMON END*/















/*ICONS START*/
/*--------------------------------------------------------------------------*/


/*--------------------------------------------------------------------------*/
/*ICONS END*/










/*HEADER START*/
/*--------------------------------------------------------------------------*/

.header {
	padding-top: 28px;
	box-shadow: 0 6px 8px 0px rgba(0,0,0,0.1); }

.logo {
	display: inline-block; }

.logo-text {
	position: relative;
	@include in;
	margin-left: 10px;
	padding-left: 33px;
	@include rem(12.4);
	color: #818080;
	line-height: 1.25; }

.logo-text-img {
	position: absolute;
	top: 3px;
	left: 0; }

.header-right {
	padding-top: 10px; }

.social-list {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		display: block;
		float: left;
		a {
			display: block;
			width: 40px;
			height: 40px;
			padding-top: 3px;
			@include rem(24);
			text-align: center;
			&.facebook {
				color: #3b5998; }

			&.twitter {
				color: #55acee; }

			&.google-plus {
				color: #dc4e41; }

			&.feed {
				color: #ecb216; } } } }

.social-list,
.header-search,
.header-lang,
.header-dropdown {
	@include middle; }

.header-search,
.header-lang,
.header-dropdown-btn,
.btn-search-black,
.header-search-form {
	height: 40px; }

.header-search {
	position: relative;
	.header-search-input {
		width: 250px; } }

.header-search-form {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 100%;
	margin-right: 3px; }

.header-search-input {
	height: 100%;
	padding-right: 50px;
	&:focus {
		border-color: #ccc; } }

.header-search-btn,
.header-lang,
.btn-search-black {
	width: 40px;
	margin-right: 1px;
	margin-left: 1px; }

.header-search-btn {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0; }

.btn-search-black {
	padding-right: 0;
	padding-left: 0;
	background: #383939;
	color: #9b9b9b;
	@include hf {
		background: darken(#383939, 5%); } }


.header-search-toggler {
	@include transitionMain;
	.open-icon {
		display: none; }
	&[aria-expanded="true"] {
		border-radius: 50%;
		background-color: #eaeaea;
		color: #383939;
		.normal-icon {
			display: none; }
		.open-icon {
			display: block; }
		&:focus {
			outline: none; } }
	&:focus {
		outline: none; } }


.header-search-input,
.header-search-addon,
.header-search-btn {
	// border: none
	// border-radius: 0
 }	// background: transparent

.header-search-input {
	// padding: 0
 }	// +rem(11)

.header-search-addon {
 }	// padding: 0

.header-search-btn {
	// display: block
	// margin-top: -1px
	// width: 24px
	// height: 100%
	// padding: 0
	// +rem(14)
 }	// color: $orange

.header-lang {
	padding-top: 10px;
	text-transform: uppercase;
	@include rem(14);
	background-color: #d4d4d4;
	color: #5a5c5d;
	text-align: center;
	@include hf {
		background-color: darken(#d4d4d4, 5%);
		text-decoration: none;
		color: #5a5c5d; } }

.header-dropdown {
	.dropdown-menu {
		left: auto;
		right: 0;
		min-width: 100%; } }

.header-dropdown-btn {
	padding-right: 18px;
	padding-left: 18px; }



/*MAIN NAV*/

.nav-main {
	padding-top: 23px; }

.navbar-nav {
	display: table;
	width: 100%;
	/*color of dropdown links*/
	color: #363636;
	.nav-item {
		display: table-cell;
		.nav-link {
			/*first level link*/
			position: relative;
			padding: 17px 11px;
			@include rem(20);
			@include transitionMain;
			color: #8f8f8f;
			text-align: center; }
		+ .nav-item {
			margin-left: 0;
			.nav-link {
				/*grey divider beetween links*/
				&:before {
					@include ct;
					position: absolute;
					top: 50%;
					left: 0;
					margin: -6px 0 0 0;
					height: 15px;
					width: 2px;
					background: #ededed; } } } } }

/*hide grey dividers beetween links on hover*/
.navbar-nav	.nav-item.active,
.navbar-nav	.nav-item:hover, {
	+ .nav-item {
		.nav-link {
			&:before {
				display: none; } } } }

.navbar-nav	.nav-item + .nav-item:hover {
	.nav-link {
		&:before {
			display: none; } } }


/*hover and active link*/
.navbar-nav .nav-item.active .nav-link,
.navbar-nav .nav-item .nav-link:hover,
.navbar-nav .nav-item .nav-link:focus,
.mash-menu .mash-list-items > li:hover > a {
	background: $blue;
	color: #fff;
	&:before {
		display: none; }
	&:after {
		opacity: 1; } }

.mash-menu section.mash-menu-inner-container {
	background: transparent; }

.mash-menu {
	max-width: 200%;
	width: auto;
	* {
		font-family: inherit;
		text-align: inherit; }
	/*dropdowns*/
	.drop-down,
	.drop-down-large,
	.drop-down-medium {
		border-radius: 0;
		box-shadow: 0 5px 6px 0 rgba(#000, 0.1); }
	.drop-down-large,
	.drop-down-medium {
		padding: 17px 0 19px 0;
		/*shadow in dropdown*/
		&:before {
			@include ct;
			position: absolute;
			top: -1px;
			right: 0;
			left: 0;
			z-index: 11;
			height: 0;
			box-shadow: 0px 0px 7px 7px rgba(#000, 0.1); }
		> .vertical-tabs-container {
			top: 0;
			margin: 0;
			background: transparent;
			padding-right: 15px; } }
	.drop-down {
		li {
			&:last-child a {
				border-radius: 0;
				border-bottom: none; }
			a {
				color: inherit; }
			&:hover, &:focus {
				a {
					color: inherit; } } }
		.drop-down {
			top: 0;
			border-radius: 0;
			li:first-child a {
				border-radius: 0; } }
		li a i.fa.fa-indicator {
			margin-top: 3px; } }
	.vertical-tabs {
		padding: 0;
		background: transparent;
		&:before {
			display: none;
 } }			// background: transparent
	img {
		margin-bottom: 0; }
	.vertical-tabs-content {
		padding: 0;
		&:before {
			display: none; } }

	.vertical-tabs,
	.nav-item ul,
	.drop-down li:last-child,
	.drop-down .drop-down li:first-child,
	.drop-down .drop-down li:last-child {
		a {
			margin: 0;
			padding: 14px 7px 11px 10px;
			font-weight: 400;
			color: inherit;
			@include rem(14);
			text-align: left;
			.fa {
				float: right;
				font-weight: inherit; }
			/*hover and active link in dropdown*/
			&:hover,
			&:focus,
			&.active {
				border-color: $blue;
				background: $blue;
				color: #fff; }
			+ a {
				border-top: #e7e5e5 1px solid; } } }
	.drop-down li + li {
		a {
			border-top: #e7e5e5 1px solid; } }

	.mash-list-items {
		> li > a {
			i.fa,
			i.fa.fa-indicator {
				float: none;
				padding-left: 2px; } } }
	.mash-brand,
	.mash-list-items {
		> li {
			> a {
				color: inherit; } } } }

.drop-down-heading {
	.fa {
		color: $blue; } }

.mash-menu [class*='col-'].col-separator,
.col-separator {
	position: relative; }

.col-separator {
	&:after {
		@include ct;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 1px;
		background: #e2e2e2; } }

// second level content class for javascript manipulations
// .vertical-tabs-content.active
// 	display: block !important

.nav-item .menu-list,
.menu-list {
	padding-left: 0;
	> li {
		> a {
			position: relative;
			display: block;
			padding-left: 35px;
			border-top: #e7e5e5 1px solid;
			.fa {
				position: absolute;
				top: 15px;
				left: 10px;
				float: none;
				margin-right: 10px; } } } }




/*--------------------------------------------------------------------------*/
/*HEADER END*/







/*FOOTER START*/
/*--------------------------------------------------------------------------*/

.footer {
	padding: 0 0 12px 0;
	background: #3d3d3d;
	color: #818181;
	hr {
		margin-top: 0;
		margin-bottom: 17px;
		background: #555; } }

.footer-dark {
	padding-top: 45px; }

.footer-nav {
	padding-bottom: 8px;
	@include rem(16);
	font-weight: 700; }

.footer-nav-list {
	list-style: none;
	padding: 0;
	li {
		@include in;
		margin-right: 0;
		+ li {
			&:before {
				content: "|";
				display: inline;
				// vertical-align: top
				margin-right: 3px;
				color: #313131;
				@include rem(15); } }
		a {
			letter-spacing: -0.035em; } } }

.footer-bottom {
	padding: 0px 0 0 0; }

.copyright {
	margin-top: 8px; }

.social-list-footer {
	// margin-top: 10px
	li {
		a {
			@include rem(18);
			width: 33px; } } }


/*--------------------------------------------------------------------------*/
/*FOOTER END*/





/*SLIDERS COMMON STYLES START*/
/*--------------------------------------------------------------------------*/

.owl-carousel {
	.owl-stage-outer {
		width: 100%; }
	.owl-item {
		img {
			width: auto; }
		.img-full-width {
			width: 100%; } } }

.slider {
	position: relative;
	.owl-item {
		.slider-main-img {
			display: block;
			width: 100%; } } }

.slider-slides {
	.owl-controls {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		.owl-dot {
			@include in;
			margin: 0 1px;
			height: 4px;
			width: 40px;
			background: #fff;
			opacity: 0.29;
			&.active {
				opacity: 1; } } } }

.owl-dots {
	margin: auto;
	padding: 0 30px; }


/*SLIDE ARROWS*/

.slider-nav {
	position: absolute;
	z-index: 1; }

.slider-nav-left,
.slider-nav-right {
	display: inline-block;
	width: 30px;
	height: 30px;
	padding-top: 4px;
	background-color: #3a3a3a;
	color: #fff;
	text-align: center;
	cursor: pointer;
	i {
		@include rem(16); }
	&:hover, &:focus {
		text-decoration: none;
		color: #fff; } }

.slider-nav-left {
	left: -17px; }

.slider-nav-right {
	right: -17px; }


/*--------------------------------------------------------------------------*/
/*SLIDERS COMMON STYLES END*/
















/*PAGES BEGIN*/
/*--------------------------------------------------------------------------*/









/*HOMEPAGE START*/
/*--------------------------------------------------------------------------*/





/*MAIN START*/
/*--------------------------------------------------------------------------*/

.main {}

.main-padding {
	padding-top: 20px; }



/*homepage main slider*/

.slider-main-nav {
	bottom: 40px;
	right: 42px; }

.slider-main-slide {
	position: relative;
	display: block;
	min-height: 300px;
	background: #e5e5e5;
	overflow: hidden;
	.container {
		min-height: 100%; }
	@include hf {
		text-decoration: none; } }

.slider-main-img {
	display: block; }

.slider-main-caption {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0; }

.slider-text {
	position: absolute;
	top: 0;
	right: 15px;
	bottom: 0;
	width: 34%;
	padding: 6% 0 70px 0;
	background: rgba($blue, 0.73);
	color: #fff;
	@include rem(18); }



/*PROGRAMS BEGIN*/
/*--------------------------------------------------------------------------*/

.programs {
 }	// padding-top: 15px

.programs-heading {
	margin-bottom: 26px; }

.program-item {
	padding-top: 15px;
	padding-bottom: 37px;
	border-bottom: transparent 5px solid;
	background-position: right bottom;
	background-repeat: no-repeat;
	color: #5b5959; }

.program-item-heading {
	margin-bottom: 2px; }

.program-item-top-text {
	height: 100px;
	margin-right: 10%;
	margin-left: 10%;
	margin-bottom: 23px; }

.program-item-separator {
	position: relative;
	height: 1px;
	opacity: 0.5;
	margin-bottom: 20px;
	&:before,
	&:after {
		@include ct;
		position: absolute;
		top: 0;
		left: 50%; }
	&:before {
		width: 22px;
		margin-left: -11px;
		border: transparent 11px solid;
		border-top: $orange 11px solid; }
	&:after {
		width: 20px;
		margin-left: -10px;
		border: transparent 10px solid;
		border-top: #fff 10px solid; } }

/*orange item*/
.program-item-orange {
	border-bottom-color: $orange;
	background-image: url(../img/program-item-orange.png);
	.program-item-separator {
		background-color: $orange;
		&:before {
			border-top-color: $orange; } }
	.program-item-heading {
		color: $orange; }
	.program-item-list {
		.fa {
			color: $orange; } } }

.program-item-list {
	padding-top: 21px; }


/*red item*/
.program-item-red {
	border-bottom-color: $red;
	background-image: url(../img/program-item-red.png);
	.program-item-separator {
		background-color: $red;
		&:before {
			border-top-color: $red; } }
	.program-item-heading {
		color: $red; }
	.program-item-list {
		.fa {
			color: $red; } } }

/*dark-blue item*/
.program-item-dark-blue {
	border-bottom-color: $blueDark;
	background-image: url(../img/program-item-dark-blue.png);
	.program-item-separator {
		background-color: $blueDark;
		&:before {
			border-top-color: $blueDark; } }
	.program-item-heading {
		color: $blueDark; }
	.program-item-list {
		.fa {
			color: $blueDark; } } }

.program-item-list {
	li {
		position: relative;
		padding-left: 22px;
		margin-bottom: 13px; }
	.fa {
		position: absolute;
		top: 3px;
		left: 14px; } }


/*--------------------------------------------------------------------------*/
/*PROGRAMS END*/









/*EVENTS BEGIN*/
/*--------------------------------------------------------------------------*/

.events {
	padding-bottom: 63px;
	background-color: #1f1f1f;
	color: #fff;
	background-image: url(../img/events.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right top; }

.section-more-link {
	@include rem(14);
	color: #cecece;
	text-transform: none;
	font-weight: 700;
	span {
		@include middle; }
	@include hf {
		color: #cecece; } }

.event {}

.event-text-container,
.event-btn-container {
	@include middle;
	width: 50%; }

.event-btn-container {
	padding-right: 30px; }

.event-date {
	display: block;
	@include rem(40);
	font-weight: 500;
	line-height: 1.35; }

.event-heading {
	margin-bottom: 1px; }

.event-text {
	height: 50px; }

/*--------------------------------------------------------------------------*/
/*EVENTS END*/









/*NEWS BEGIN*/
/*--------------------------------------------------------------------------*/

.news {
	padding-bottom: 27px; }

.news-previews-container {}

.news-video,
.news-preview {
	padding-top: 13px; }

.news-preview {
	margin-top: 0;
	padding-bottom: 8px;
	+ .news-preview {
		border-top: #ececec 1px solid; } }

.news-preview-icon-and-date {
	padding-top: 4px;
	padding-right: 14px; }

.news-icon,
.news-preview-date {
	width: 48px;
	height: 48px;
	text-align: center;
	color: #fff; }

.news-icon {
	display: block;
	background-color: $violet;
	@include rem(25);
	@include hf {
		color: #fff; } }

.news-preview-date-link {}

.news-preview-date {
	display: block;
	background-color: rgba($violet, 0.35);
	@include rem(16);
	line-height: 1.2; }

.news-preview-heading {
	height: 43px;
	line-height: 1.2;
	margin-bottom: 0px; }

.news-preview-text {
	height: 65px; }


/*--------------------------------------------------------------------------*/
/*NEWS END*/










/*LIFE BEGIN*/
/*--------------------------------------------------------------------------*/

.life {
	min-height: 300px;
	background-color: rgba(#024c7f, 0.8);
	overflow: hidden;
	color: #fff; }

.life-side-container {
	margin-right: -135px;
	margin-left: -135px; }

.life-post {
	position: relative; }

.life-post-img-container {
	position: relative;
	overflow: hidden;
	text-align: center;
	@include transform(skew(-22deg)); }

.life-post-img {
	display: inline-block;
	width: 100%;
	@include transform(skew(22deg));
	opacity: 0.32; }

.life-post-img-container-left {
	.life-post-img {
		margin-right: -210px; } }

.life-post-img-container-right {
	.life-post-img {
		margin-left: -210px; } }

.life-post-img-container-blue {
	background-color: #024c7f; }

.life-post-img-container-violet {
	background-color: $violet; }

.life-post-text {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	padding-top: 30px;
	padding-bottom: 30px;
	max-width: $containerWidth/2 + px; }

.life-post-text-left {
	right: 0;
	padding-right: 16%; }

.life-post-text-right {
	left: 0;
	padding-left: 16%; }

.life-post-text-overflow {
	max-height: 130px;
	margin-bottom: 22px; }

.life-post-heading {
	margin-bottom: 2px; }


/*--------------------------------------------------------------------------*/
/*LIFE END*/









/*TESTIMONIALS BEGIN*/
/*--------------------------------------------------------------------------*/

.testimonials {
	padding: 123px 0 59px 0; }

.testimonials-areas {}

.testimonials-areas-item {
	+ .testimonials-areas-item {
			margin-top: 22px; } }

.testimonials-areas-item-left {
	padding-right: 30px;
	padding-top: 8px; }

.testimonials-area-icon {
	display: block;
	width: 65px;
	height: 65px;
	text-align: center; }

.testimonials-area-icon-orange {
	background-color: $orange; }

.testimonials-area-icon-red {
	background-color: $red; }

.testimonials-areas-heading {
	margin-bottom: 0px; }

.testimonials-areas-item-text {
	height: 40px; }

.promotions {
	padding-bottom: 31px;
	line-height: 0.9;
	background: url(../img/promotions.png) 10px bottom no-repeat; }

.promotions-link,
.promotions-number,
.promotions-text {
	display: block; }

.promotions-link {
	@include hf {
		color: $violet;
		text-decoration: none; } }

.promotions-number {
	float: left;
	// +rem(126)
	@include rem(120);
	line-height: 0.8;
	margin-right: 9px; }

.promotions-text {
	padding-top: 24px;
	@include rem(34);
	line-height: 0.9; }

.testimonials-slider {
	position: relative;
	margin-top: 7px;
	.owl-item {
		img {
			@include middle; } }
	.owl-controls {
		margin-top: 23px; }
	.owl-dots {
		text-align: center; }
	.owl-dot {
		@include in;
		width: 6px;
		height: 6px;
		margin: 0 2px;
		background: $violet;
		border-radius: 50%;
		opacity: 0.29;
		&.active {
			opacity: 1; } } }

.testimonials-slides {}

.testimonial {}

.testimonial-text {
	position: relative;
	padding: 20px 21px;
	margin-bottom: 7px;
	background: #efefef;
	border-radius: 4px;
	font-style: italic;
	&:after {
		@include ct;
		position: absolute;
		top: 100%;
		left: 32px;
		border: transparent 10px solid;
		border-top: #efefef 10px solid; }
	p {
		margin-bottom: 0; } }

.testimonial-text-overflow {
	display: inline-block;
	height: 80px;
	&:before,
	&:after {
		display: inline-block;
		color: $violet; }
	&:before {
		content: "“"; }
	&:after {
		content: "”"; } }

.testimonial-author {
	padding-left: 50px;
	padding-right: 50px;
	img {
		margin-right: 3px; } }

.testimonial-author-name {
	@include middle;
	font-weight: 400; }

.testimonials-more {
	position: absolute;
	bottom: 54px;
	right: 15px;
	z-index: 1; }


/*--------------------------------------------------------------------------*/
/*TESTIMONIALS END*/










/*GALLERY BEGIN*/
/*--------------------------------------------------------------------------*/

.gallery {
	padding: 30px 0;
	background-color: #f2f2f2; }

.gallery-link {
	display: block; }

.col-2-5,
.col-1-5 {
	float: left; }

.col-2-5 {
	width: 40%; }

.col-1-5 {
	width: 20%; }

.gallery-img-big,
.gallery-img-small {
	display: block;
	width: 100%;
	height: auto; }

.gallery-img-big {}

.gallery-img-small {}

.gallery-text-container {
	position: relative; }

.gallery-text {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding-top: 31px;
	padding-bottom: 15px;
	background: rgba($violet, 0.8);
	color: #fff;
	line-height: 1.45;
	p {
		margin-bottom: 0; } }


/*--------------------------------------------------------------------------*/
/*GALLERY END*/


/*SUBSCRIBE BEGIN*/
/*--------------------------------------------------------------------------*/

.subscribe {
	padding: 30px 0 22px 0;
	background-color: #e1e1e1; }

.subscribe-heading {
	margin-bottom: 4px; }

.subscibe-text-overflow {
	height: 60px;
	color: #707070; }

.subscibe-form {
	margin-top: 35px;
	.form-control {
		margin-right: 4px;
		width: 303px;
 } }		// max-width: 100%


/*--------------------------------------------------------------------------*/
/*SUBSCRIBE END*/



/*--------------------------------------------------------------------------*/
/*MAIN END*/




/*--------------------------------------------------------------------------*/
/*HOMEPAGE END*/








/*ARTICLE PAGE BEGIN*/
/*--------------------------------------------------------------------------*/

.breadcrumb-custom {
	padding: 0;
	margin: 0 0 13px 0;
	> li {
		@include in;
		color: #c1c1c1;
		&.active {
			color: $blue; }
		.fa {
			padding-right: 7px;
			padding-left: 7px;
			color: $blue; } } }




/*SIDEBAR BEGIN*/
/*--------------------------------------------------------------------------*/

.sidebar {}

.heading-underline {
	position: relative;
	display: inline-block;
	padding-bottom: 2px;
	&:after {
		@include ct;
		position: absolute;
		top: 100%;
		left: 0;
		width: 33px;
		height: 3px;
		@include transitionMain; }
	&.heading-underline-violet:after {
		background-color: $violet; }
	&.heading-underline-white:after {
		background-color: #fff; } }

a.heading-underline {
	@include hf {
		text-decoration: none;
		&:after {
			width: 100%; } } }

.sidebar-events {
	padding: 27px 13px 44px 13px;
	.section-heading {
		margin-bottom: 57px; }
	.event-text {
		margin-bottom: 24px; } }

.sidebar-event-more-link {
	margin-top: 7px; }

.sidebar-testimonials-areas {
	padding-top: 20px;
	padding-bottom: 10px; }

.sidebar-news-previews-container {}
.news-preview:first-child {
	padding-top: 0; }


/*--------------------------------------------------------------------------*/
/*SIDEBAR END*/





/*CONTENT BEGIN*/
/*--------------------------------------------------------------------------*/

.content {}

.post {
	p {
		line-height: 1.8; } }

/*slider*/

.slider-article-nav {
	right: 0;
	bottom: 0; }

.article-media {
	margin-top: 0;
	.news-preview-icon-and-date {
		padding-top: 0; } }

.article-heading {
	padding-top: 7px; }

.article-social {
	margin: 30px 0 17px 0;
	.list-inline-item {
		a {
			display: block;
			width: 36px;
			height: 36px;
			border-radius: 50%;
			background-color: #85939c;
			@include rem(16);
			color: #fff;
			text-align: center;
			.fa {
				display: table;
				width: 100%;
				height: 100%;
				&:before {
					display: table-cell;
					vertical-align: middle; } } } }
	.list-inline-item:not(:last-child) {
		margin-right: 0; } }

.blockquote {
	position: relative;
	display: block;
	padding: 59px 27px;
	margin: 39px 0 23px 0;
	background-color: $blue;
	background-image: url(../img/blockquote.png);
	background-position: 0 bottom;
	background-repeat: no-repeat;
	border-left: none;
	font-style: italic;
	font-weight: 700;
	@include rem(24);
	color: #fff;
	overflow: hidden;
	&:after,
	&:before {
		@include ct;
		position: absolute; }
	&:after {
		bottom: 0;
		right: 0;
		z-index: 0;
		width: 30px;
		height: 30px;
		background-color: #024c7e; }
	&:before {
		bottom: -21px;
		right: -21px;
		z-index: 1;
		width: 0;
		height: 0;
		border: transparent 21px solid;
		border-top: #fff 21px solid;
		@include transform(rotate(-45deg)); }
	p {
		line-height: 1.2; } }

/*slider*/

.gallery-slider {
	padding-top: 26px; }

.gallery-slider-header {
	margin-bottom: 14px; }

.gallery-slider-header,
.gallery-slider-nav {
	margin-bottom: 10px; }

.gallery-slide {
	position: relative;
	float: left;
	display: block;
	width: 142px;
	height: 142px;
	// margin-right: 15px
	// margin-left: 15px
	@include hf {
		.gallery-slide-overlay {
			@include transform(none); } } }

.gallery-slide-img {
	display: block;
	width: 100%;
	height: 100%; }

.gallery-slide-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($blue, 0.57);
	@include transform(scale(0.001));
	@include transitionMain;
	color: #fff;
	text-align: center;
	@include rem(35); }

.gallery-slider-nav {
	position: static; }

.articles-nav {
	padding: 20px 10px 14px 10px;
	background-color: #dfdfdf;
	text-align: center;
	color: $blue;
	@include rem(18); }

.articles-nav-dots {
	@include in;
	margin: 0;
	padding: 14px 0 0 0;
	li {
		@include in;
		opacity: 0.47;
		.articles-nav-dot {
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: $blue; }
		&.active {
			opacity: 1; } } }

.articles-nav-arrow {
	padding: 5px 10px; }

.articles-nav-arrow-left {
	float: left; }

.articles-nav-arrow-right {
	float: right; }


/*--------------------------------------------------------------------------*/
/*CONTENT END*/




/*--------------------------------------------------------------------------*/
/*ARTICLE PAGE END*/











/*CONTACT PAGE BEGIN*/
/*--------------------------------------------------------------------------*/

.contact-form {
	textarea.form-control {
		height: 100%;
		min-height: 100px; } }


/*--------------------------------------------------------------------------*/
/*CONTACT PAGE END*/







/*FAQs BEGIN*/
/*--------------------------------------------------------------------------*/

.faqs-container {}

.faq-row {}

.comment-box {
	position: relative;
	max-width: 510px;
	margin-bottom: 25px;
	padding: 22px 30px 19px 30px;
	&:after {
		@include ct;
		position: absolute;
		bottom: -6px;
		width: 18px;
		height: 18px; }
	&.blue {
		margin-right: 50px;
		background-color: $blue;
		color: #fff;
		&:after {
			left: 22px;
			background-color: $blue;
			@include transform(skew(-40deg) rotate(-49deg)); } }
	&.grey {
		margin-left: 50px;
		background-color: #edeeef;
		color: #3e404f;
		&:after {
			right: 22px;
			background-color: #edeeef;
			@include transform(skew(40deg) rotate(-49deg)); } }
	p {
		line-height: 1.4; } }

/*--------------------------------------------------------------------------*/
/*FAQs END*/






/*PROGRAMS PAGE BEGIN*/
/*--------------------------------------------------------------------------*/

.program-box {
	display: block;
	color: #fff;
	background-color: #aaa;
	@include hf {
		text-decoration: none;
		color: #fff; } }

.program-box-orange {
	background-color: $orange; }

.program-box-red {
	background-color: $red; }

.program-box-blue-dark {
	background-color: $blueDark; }

.program-box-heading {
	margin-bottom: 4px; }

.program-box-text {
	position: relative;
	width: 45%;
	min-height: 100%;
	padding: 42px 30px 100px 30px;
	p {
		margin-bottom: 0;
		line-height: 1.4;
		+ p {
			margin-top: 10px; } } }

.program-box-text-overflow {
	height: 67px; }

.program-box-arrow {
	position: absolute;
	bottom: 30px;
	right: 31px; }

.program-box-img-container {
	width: 55%; }

.program-box-img {
	position: relative;
	right: -1px;
	display: block;
	width: 100%;
	opacity: 0.6; }

.article-social-programs {
	margin-top: 45px; }

/*--------------------------------------------------------------------------*/
/*PROGRAMS PAGE END*/







/*PROGRAMS CYCLE PAGE BEGIN*/
/*--------------------------------------------------------------------------*/

.programs-cycle-boxes {}

.programs-cycle-box {
	position: relative;
	display: block;
	padding: 43px 30px 38px 30px;
	background-color: $orange;
	color: #fff;
	@include hf {
		text-decoration: none;
		color: #fff; } }

.programs-cycle-box-red {
	background-color: $red; }

.programs-cycle-box-blue-dark {
	background-color: $blueDark; }

.programs-cycle-box-heading {
	margin-bottom: 0px; }

.programs-cycle-box-text-overflow {
	height: 75px;
	p {
		line-height: 1.5; } }

.programs-cycle-box-arrow {
	position: absolute;
	right: 15px;
	bottom: 12px; }

/*--------------------------------------------------------------------------*/
/*PROGRAMS CYCLE PAGE END*/



/* ARTICLES PAGE BEGIN*/
/*--------------------------------------------------------------------------*/

.articles-previews-container {}

.article-preview {
	margin-bottom: 30px; }

.news-preview-article-preview {
	padding-bottom: 18px;
	border-bottom: #ececec 1px solid;
	.news-preview-icon-and-date {
		padding-top: 0; } }

.article-preview-heading {
	max-height: 24px; }

.article-preview-text {
	max-height: 70px;
	margin-bottom: 20px; }

.article-preview-img {
	margin-bottom: 10px; }

.articles-preview-pagination {
	margin-bottom: 15px; }

.articles-preview-pagination-link {
	display: block;
	width: 48px;
	height: 48px;
	padding-top: 11px;
	border-radius: 50%;
	background-color: #ebeff2;
	color: #acaeb0;
	font-weight: 700;
	text-align: center;
	@include rem(18);
	@include hf {
		background-color: $blue;
		color: #fff; } }












































/*--------------------------------------------------------------------------*/
/* ARTICLES PAGE END*/









/* PAGE BEGIN*/
/*--------------------------------------------------------------------------*/


/*--------------------------------------------------------------------------*/
/* PAGE END*/














/*--------------------------------------------------------------------------*/
/*PAGES END*/








